$(document).on('ready turbolinks:load', function() {

    $("select[name='user[raffle_limit_type]']").change(function() {
      var val = $("select[name='user[raffle_limit_type]']").val();
      if (val != "none") {
        $(".user_raffle_limit_number").slideDown()
      } else {
        $(".user_raffle_limit_number").slideUp()
      }
    })
    $("select[name='user[raffle_limit_type]']").trigger('change');

    $("select[name='user[reservation_limit_type]']").change(function() {
      var val = $("select[name='user[reservation_limit_type]']").val();
      if (val != "none") {
        $(".user_reservation_limit_number").slideDown()
      } else {
        $(".user_reservation_limit_number").slideUp()
      }
    })
    $("select[name='user[reservation_limit_type]']").trigger('change');

    $("input[name='facility_use_setting']").change(function() {
      if ($("input[name='facility_use_setting']").prop("checked")) {
        $(".facility_use_list").slideDown()
      } else {
        $(".facility_use_list").slideUp()
      }
    })
    $("input[name='facility_use_setting']").trigger('change');

    $("#ngButton").click(function() {
      const facilityIds = document.querySelectorAll('.col-1.text-right input[type="hidden"]');
      var useType = "impossible";

      facilityIds.forEach(function(element) {
        var facilityid = "#" + element.value.trim();
        // select要素の選択肢を変更
        $(facilityid).val(useType);

      });

    });

    $("#okButton").click(function() {
      const facilityIds = document.querySelectorAll('.col-1.text-right input[type="hidden"]');
      var useType = "";

      facilityIds.forEach(function(element) {
        var facilityid = "#" + element.value.trim();
        // select要素の選択肢を変更
        $(facilityid).val(useType);
      });

    });
    
    const items = $(".col-4.text-right"); // 施設を取得
    const searchInput = $("#searchInput");
  
    searchInput.on("input", function() {   
      const noMatchMessage = $("#noMatchMessage")
      let hasMatch = false; // 部分一致が見つかったかどうかのフラグ 
      items.each(function() {
        if ($(this).text().includes(searchInput.val())) {
          $(this).parent().css("display", "block"); // 部分一致する場合、表示
          hasMatch = true; // 部分一致検出
        } else {
          $(this).parent().css("display", "none"); // 非表示
        }
      });
      if (!hasMatch) {
        noMatchMessage.css("display", "block");
      } else {
        noMatchMessage.css("display", "none"); // 部分一致が見つかった場合、メッセージを非表示にする
      }
    });
      

});

$(window).on('pageshow', function() {
    $("select[name='user[raffle_limit_type]']").trigger('change');
    $("select[name='user[reservation_limit_type]']").trigger('change');
    $("input[name='facility_use_setting']").trigger('change');
});
